import React, { useEffect } from "react"

import { Layout, Seo, Theme } from "../components"
import useOnlyRenderIfBrowser from "../hooks/useOnlyRenderIfBrowser"

const Licensing = () => {
  const isBrowser = useOnlyRenderIfBrowser()

  useEffect(() => {
    if (isBrowser) {
      window.location = `https://licensing.gaming-curacao.com/validator/?lh=9f83b305d6344e5c95267ec34f2d8789`
    }
  })

  return (
    <Layout>
      <Seo title="Licensing" />
      <Theme.Wrap size="narrow">
        <p style={{ padding: `10rem 0 5rem`, textAlign: `center` }}>
          Redirecting...
        </p>
      </Theme.Wrap>
    </Layout>
  )
}

export default Licensing
